import { createStore, Store, useStore as vuexUseStore } from "vuex";
import { InjectionKey } from 'vue'
import { EstadoDate, date } from '@/store/modules/date';


export interface Estado {
  date: EstadoDate,
  loading: boolean
}

export const key: InjectionKey<Store<Estado>> = Symbol()

export const store = createStore<Estado>({
  state: {
    date: {
      dates: []
    },
    loading: true,
  },
  getters: {
  },
  mutations: {
    loading(state, status) {
      state.loading = status
    },
  },
  actions: {
    updateLoading({ commit }, status) {
      commit('loading', status)
    },
  },
  modules: {
    date
  }
})

export function useStore(): Store<Estado> {
  return vuexUseStore(key)
}
