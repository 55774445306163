import http from '@/http'

class SchedulingService {
    async post(data: any) {
        const response = await http.post('agendamento/novo', data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
        return response
    }
    async getScheduling(data: any) {
        const response = await http.post('agendamento/acompanhar', data)
        return response
    }
    async getList() {
        const response = await http.get('agendameto/lista')
        return response
    }
    async getCorrecao(filter: string) {
        const response = await http.get(`agendamento/correcao/${filter}`)
        return response.data
    }
    async getConfirmacao(url: string) {
        const response = await http.get(`agendamento/confirmacao/${url}`)
        return response.data
    }
    async putCorrecao(data: any, url: string) {
        const response = await http.put(`agendamento/correcao/${url}`, data)
        return response
    }
    async putConfirmacao(data: any, url: string) {
        const response = await http.put(`agendamento/confirmacao/${url}`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
        return response
    }
}

export default new SchedulingService()
