
function isEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

import { defineComponent, ref } from "vue";
import { useStore } from "@/store";
import { useToast } from "primevue/usetoast";
import SchedulingService from "@/services/scheduling";

import moment from "moment";

export default defineComponent({
  name: "Acompanhar",
  setup() {
    const toast = useToast();
    const store = useStore();

    store.dispatch("updateLoading", false);

    const agendamento = ref({
      codigoAgendamento: {
        value: "",
        req: true,
        erro: false,
      },
      emailResponsavel: {
        value: "",
        req: true,
        erro: false,
      },
    });

    const sucessoForms = ref(false);

    const dadosAgendamento = ref({
      inepSchoolScheduling: "",
      nameResponsible: "",
      schoolScheduling: "",
      citySchool: "",
      stateSchool: "",
      status: 0,
      createdAt: "",
      calendar: {
        date: "",
      },
    });

    const erroForms = ref({
      active: false,
      buttom: true,
      message: "",
    });

    const contexto = {
      [0]: "visita-cancelado",
      [1]: "visita-aguardando",
      [2]: "visita-reservado",
      [3]: "visita-revisao",
      [4]: "visita-confirmado",
    };

    const textoStatus = {
      [0]: "Cancelado",
      [1]: "Aguardando aprovação",
      [2]: "Reservado",
      [3]: "Correção",
      [4]: "Confirmado",
    };

    const validaForms = () => {
      var erroStatus = false;

      for (let [key, input] of Object.entries(agendamento.value)) {
        if (input.req && (input.value === "" || input.value === null)) {
          input.erro = true;
          erroStatus = true;
        } else {
          input.erro = false;
        }
      }
      //validaEmail
      if (isEmail(agendamento.value.emailResponsavel.value)) {
        agendamento.value.emailResponsavel.erro = false;
      } else {
        agendamento.value.emailResponsavel.erro = true;
        erroStatus = true;
      }

      return erroStatus;
    };

    const checkInputEmail = (inputEmail: any) => {
      if (isEmail(inputEmail.value)) {
        inputEmail.erro = false;
      } else {
        inputEmail.erro = true;
      }
    };

    const changeInputEmail = (inputEmail: any) => {
      if (inputEmail.erro) {
        checkInputEmail(inputEmail);
      }
    };

    const checkInput = (input: any) => {
      if (input.value !== "") {
        input.erro = false;
      } else {
        input.erro = true;
      }
    };

    const changeInput = (input: any) => {
      if (input.erro) {
        checkInput(input);
      }
    };

    const formatData = (data: string) => {
      if (data) {
        return moment(data.split("T")[0]).format("DD/MM/YYYY");
      }
      return null;
    };
    const convertDataHora = (data: string) => {
      return moment(data).format("DD/MM/YYYY HH:mm:ss");
    };
    const submitForms = async () => {
      sucessoForms.value = false;
      store.dispatch("updateLoading", true);
      if (validaForms()) {
        store.dispatch("updateLoading", false);
        toast.add({
          severity: "error",
          summary: "Erro ao enviar",
          detail:
            "Verifique o formulário e preencha todos os campos obrigatórios!",
          life: 6000,
        });
      } else {
        try {
          const dataScheduling = {
            emailResponsavel: agendamento.value.emailResponsavel.value,
            codigoAgendamento: agendamento.value.codigoAgendamento.value,
          };
          const response = await SchedulingService.getScheduling(
            dataScheduling
          );
          if (response) {
            sucessoForms.value = true;
            dadosAgendamento.value = response.data;
            store.dispatch("updateLoading", false);
          }
        } catch (erro: any) {
          store.dispatch("updateLoading", false);
          erroForms.value.message =
            erro?.response?.data?.message || "Erro interno. Tente novamente!";
          erroForms.value.buttom = true;
          erroForms.value.active = true;
        }
      }
    };
    return {
      submitForms,
      erroForms,
      agendamento,
      checkInputEmail,
      changeInputEmail,
      changeInput,
      checkInput,
      sucessoForms,
      dadosAgendamento,
      formatData,
      convertDataHora,
      contexto,
      textoStatus,
    };
  },
});
