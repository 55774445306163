export const regulamento = [
    '1. Leia atentamente o presente Regulamento. Atenção as condições que devem ser atendidas por todos durante a visita a Sala do Conhecimento.',
    '2. A participação no Universitário por Um Dia só é possível após a aprovação do Agendamento.',
    '3. A pontualidade no horário de chegada é primordial para que a atividade proposta seja totalmente realizada.Em caso de atraso, a programação poderá sofrer alterações.',
    '4. Em caso de faltas sem aviso prévio a escola terá o cadastro bloqueado por um ano.',
    '5. A Sala do Conhecimento comporta 60 (sessenta) lugares sentados. Num mesmo dia de visita poderão estar presentes mais de uma Escola, desde que atenda ao limite de lugares disponíveis(60).',
    '6. O professor é o agente responsável pelo monitoramento dos alunos durante as atividades e no interior do Campus USP.',
    '7. Os visitantes deverão manter a ordem e silêncio nos corredores do edifício, especialmente por se tratar de um ambiente com salas de aula.',
    '8. Como requisito de segurança, é indispensável que os visitantes utilizem calçados fechados, pois estarão em um laboratório.Chinelos ou outros tipos de calçados abertos não serão permitidos.',
    '9. Não dispomos de guarda-volumes, portanto, pedimos que deixem mochilas, bolsas grandes e outros volumes no ônibus.Não é permitida a entrada nos laboratórios desses itens.',
    '10. Durante as demonstrações dos experimentos, jogos eletrônicos e players de música deverão permanecer desligados.',
    '11. Permite-se caneta e caderno de anotações, filmagens e fotografias.',
    '12. Os professores e estudantes terão acesso ao Restaurante Universitário – que é parte da programação do U1D.O horário previsto para o almoço é das 11h30 às 13h. Nesse intervalo de tempo os estudantes poderão caminhar pelo Campus, sob a supervisão dos professores ou responsáveis.',
    '13. É obrigatório aos estudantes preencherem o QAS (Questionário de Avaliação Socioeconômica) e atenderem à outras demandas durante a atividade.',
    '14. É proibido o consumo de alimentos e bebidas no interior da Sala do Conhecimento.',
    '15. Para a segurança e conforto de todos não é permitido correr ou gritar em qualquer área do Conjunto de Apoio Didático, onde está localizada a Sala do Conhecimento.',
    '16. É expressamente proibido fumar nas dependências dos prédios do Campus da USP.',
    '17. Solicita-se que estas informações sejam repassadas aos estudantes antes da visitação.',
    '18. Em caso de imprevisto no cumprimento do horário marcado, avisar pelo WhattsApp: (16) 3364 8282, o mais breve possível.',
]
