import http from '@/http'

class CalendarService {
    async get() {
        const response = await http.get('datas')
        return response.data
    }
}

export default new CalendarService()
