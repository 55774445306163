import { IDates } from "@/interfaces/IScheduling"
import { Estado } from "@/store";
import { Module } from "vuex";

import CalendarService from '@/services/calendar'

export interface EstadoDate {
    dates: IDates[]
}
export const date: Module<EstadoDate, Estado> = {
    state: {
        dates: [],
    },
    actions: {
        async getDate({ commit }) {
            const response = await CalendarService.get()
            commit('setDate', response)
            return response
        },
    },
    mutations: {
        setDate(state, dates: IDates[]) {
            state.dates = dates
        },
    }
}