import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import AcompanharView from '../views/AcompanharView.vue'
import AgendamentoView from '../views/AgendamentoView.vue'
import CancelamentoView from '../views/CancelamentoView.vue'
import ListaView from '../views/ListaView.vue'
import ManutencaoView from '../views/ManutencaoView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/agendamento',
    name: 'Novo Agendamento',
    component: AgendamentoView
  },
  {
    path: '/agendamento/:token',
    name: 'Agendamento',
    component: AgendamentoView
  },
  {
    path: '/agendamento/correcao/:url',
    props: true,
    name: 'Correção do Agendamento',
    component: () => import("@/views/CorrecaoView.vue"),
  },
  {
    path: '/agendamento/confirmacao/:url',
    props: true,
    name: 'Confirmação do Agendamento',
    component: () => import("@/views/ConfirmacaoView.vue"),
  },
  {
    path: '/acompanhar',
    name: 'Acompanhar',
    component: AcompanharView
  },
  {
    path: '/cancelamento',
    name: 'Cancelamento',
    component: CancelamentoView
  },
  {
    path: '/agendamentos/consulta/completo/data-quantidade',
    name: 'Lista',
    component: ListaView
  },
  {
    path: '/manutencao',
    name: 'Manutencao',
    component: ManutencaoView
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import("@/views/404View.vue"),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
