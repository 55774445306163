
import { defineComponent, ref } from "vue";
import { useStore } from "@/store";
import SchedulingService from "@/services/scheduling";
import { FilterMatchMode } from "primevue/api";

export default defineComponent({
  name: "Lista",
  setup() {
    const textoStatus = {
      [2]: "Reservado",
      [4]: "Confirmado",
    };

    const list = ref([]);
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    return {
      list,
      filters,
      textoStatus,
    };
  },
  async created() {
    try {
      const store = useStore();
      const list = await SchedulingService.getList();
      this.list = list.data;

      store.dispatch("updateLoading", false);
    } catch (_erro) {
      alert("Erro ao gerar a lista! Recarregue a página e tente novamente!");
    }
  },
});
