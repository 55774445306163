
import {
  defineComponent,
  computed,
  watchEffect,
  onBeforeMount,
  ref,
} from "vue";
import { useStore } from "@/store";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";

import SchoolService from "@/services/school";
import SchedulingService from "@/services/scheduling";
import CalendarService from "@/services/calendar";

import moment from "moment";
import { constants } from "@/util/const";
import QrcodeVue from "qrcode.vue";
import Pix from "@/util/Pix";

function isValidCPF(cpf: string) {
  cpf = cpf.replace(/[\s.-]*/gim, "");
  if (
    !cpf ||
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  ) {
    return false;
  }
  var soma = 0;
  var resto;
  for (var i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto == 10 || resto == 11) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) return false;
  return true;
}
function isEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
interface ProfessorData {
  nameTeachers: {
    value: string;
    req: boolean;
    erro: boolean;
  };
  emailTeachers: {
    value: string;
    req: boolean;
    erro: boolean;
  };
  phoneTeachers: {
    value: string;
    req: boolean;
    erro: boolean;
  };
}

interface EscolaData {
  codeInepSchool: string;
  nameSchool: string;
  citySchool: string;
  stateSchool: string;
  typeSchool: number;
}

interface CalendarData {
  key: string;
  customData: {
    vagas: number;
    classColor: string;
  };
  dates: string;
}

export default defineComponent({
  name: "Formulario",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      displayPosition: false,
      transitioning: false,
      brand: null,
      masks: {
        weekdays: "WWW",
      },
    };
  },
  setup() {
    const toast = useToast();
    const store = useStore();
    const confirm = useConfirm();

    const selectedSchool = ref<EscolaData | null>(null);
    const selectedClickSchool = ref(false);
    const emptySchool = ref(false);
    const erroSchool = ref(false);
    const filteredSchool = ref([]);
    const displayMaximizable = ref(false);
    const displayCalendar = ref(false);
    const numVagas = ref(0);

    const tokenId = Date.now().toString(16).toUpperCase();
    const identificador = `UP1D${tokenId}T`;
    const identificadorRefeicao = `UP1D${tokenId}R`;
    const pix = new Pix(
      "+5516996065281",
      identificador,
      "FAFQ",
      "SAO CARLOS",
      identificador,
      50.0
    );

    const codigoPix = ref(pix.getPayload());

    const lerRugulamento = ref(false);

    const sucessoForms = ref(false);
    const codigoAgendamento = ref("");

    const textErroTotalAlunos = ref("");

    const regulamentoAlmoco = ref({
      value: false,
      erro: false,
    });

    const file = ref("");
    const erroUpload = ref(false);

    const attributes = ref<CalendarData[]>([]);

    const steps = ref([
      {
        label: "1",
        info: "Formulário",
        active: true,
        to: "/agendamento",
      },
      {
        label: "2",
        info: "Informações",
        active: false,
        to: "/agendamento",
      },
      {
        label: "3",
        info: "Taxa",
        active: false,
        to: "/agendamento",
      },
      {
        label: "4",
        info: "Resumo",
        active: false,
        to: "/agendamento",
      },
    ]);

    const totalParticipantes = ref(0);
    const valorUnidade = ref(0);

    const erroForms = ref({
      active: false,
      buttom: true,
      message: "",
    });

    const professores = ref([] as Array<ProfessorData>);

    const agendamento = ref({
      nomeResponsavel: {
        value: "",
        req: true,
        erro: false,
      },
      emailResponsavel: {
        value: "",
        req: true,
        erro: false,
      },
      cpfResponsavel: {
        value: "",
        req: true,
        erro: false,
      },
      telefoneResponsavel: {
        value: "",
        req: true,
        erro: false,
      },
      data: {
        value: "",
        req: true,
        erro: false,
      },
      serie1: {
        value: 0,
        req: false,
        erro: false,
      },
      serie2: {
        value: 0,
        req: false,
        erro: false,
      },
      serie3: {
        value: 0,
        req: false,
        erro: false,
      },
      totalParticipantes: {
        value: 0,
        req: false,
        erro: false,
      },
      regulamento: {
        value: false,
        req: true,
        erro: false,
      },
    });

    store.dispatch("updateLoading", true);

    onBeforeMount(async () => {
      try {
        const response = await CalendarService.get();
        let classColor = "";
        for (var index in response) {
          const dataIso = new Date(response[index].data);

          if (response[index].vagas < 10) {
            classColor = "color-data-1";
          } else if (
            response[index].vagas >= 10 &&
            response[index].vagas < 30
          ) {
            classColor = "color-data-2";
          } else if (
            response[index].vagas >= 30 &&
            response[index].vagas < 50
          ) {
            classColor = "color-data-3";
          } else if (
            response[index].vagas >= 50 &&
            response[index].vagas < 70
          ) {
            classColor = "color-data-4";
          } else {
            classColor = "color-data-5";
          }

          attributes.value.push({
            key: response[index].data,
            customData: {
              vagas: response[index].vagas,
              classColor,
            },
            dates: dataIso.toISOString().slice(0, -1),
          });
        }
        store.dispatch("updateLoading", false);
      } catch {
        store.dispatch("updateLoading", false);
        erroForms.value.message =
          "Não foi possível carregar as datas. Verifique a conexão com a internet e tente novamente!";
        erroForms.value.buttom = false;
        erroForms.value.active = true;
      }
    });

    watchEffect(() => {
      if (selectedSchool.value === null) {
        emptySchool.value = false;
      }

      agendamento.value.totalParticipantes.value =
        agendamento.value.serie1.value +
        agendamento.value.serie2.value +
        agendamento.value.serie3.value;
    });

    const removeSchool = (event: any) => {
      confirm.require({
        target: event.currentTarget,
        message: "Deseja remover escola selecionada?",
        acceptLabel: "Sim",
        rejectLabel: "Não",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptIcon: "pi pi-check",
        rejectIcon: "pi pi-times",
        accept: () => {
          selectedClickSchool.value = false;
          selectedSchool.value = null;
        },
      });
    };

    const copiarTexto = () => {
      const textarea = document.createElement("textarea");
      textarea.value = codigoPix.value;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast.add({
        severity: "success",
        summary: "Copiado!",
        detail: "Código PIX Copia e Cola.",
        life: 6000,
      });
    };

    const handleFileUpload = (e: any) => {
      erroUpload.value = false;
      const selectedFile = e.target.files[0]; // accessing file
      file.value = selectedFile;
    };

    const removeUpload = () => {
      file.value = "";
    };

    const searchSchool = async (event: any) => {
      try {
        const response = await SchoolService.getFilter(event.query);
        if (response) {
          erroSchool.value = false;
          if (response.length) {
            emptySchool.value = false;
          } else {
            emptySchool.value = true;
          }
          filteredSchool.value = response;
        }
      } catch (erro) {
        erroSchool.value = true;
      }
    };

    const clickSchool = () => {
      selectedClickSchool.value = true;
    };

    const removeProfessor = (index: number) => {
      professores.value.splice(index, 1);
    };

    const addProfessor = () => {
      const val = {
        nameTeachers: {
          value: "",
          req: true,
          erro: false,
        },
        emailTeachers: {
          value: "",
          req: true,
          erro: false,
        },
        phoneTeachers: {
          value: "",
          req: false,
          erro: false,
        },
      };
      professores.value.push(val);
    };

    const validaForms = () => {
      var erroStatus = false;

      for (let [key, input] of Object.entries(agendamento.value)) {
        if (
          input.req &&
          (input.value === "" || input.value === null || input.value === false)
        ) {
          input.erro = true;
          erroStatus = true;
        } else {
          input.erro = false;
        }
      }
      //ValidaCPF
      if (isValidCPF(agendamento.value.cpfResponsavel.value)) {
        agendamento.value.cpfResponsavel.erro = false;
      } else {
        agendamento.value.cpfResponsavel.erro = true;
        erroStatus = true;
      }

      //validaEmail
      if (isEmail(agendamento.value.emailResponsavel.value)) {
        agendamento.value.emailResponsavel.erro = false;
      } else {
        agendamento.value.emailResponsavel.erro = true;
        erroStatus = true;
      }

      if (agendamento.value.totalParticipantes.value >= 10) {
        agendamento.value.totalParticipantes.erro = false;
      } else {
        textErroTotalAlunos.value = `Mínimo de 10 alunos para o agendamento`;
        agendamento.value.totalParticipantes.erro = true;
        erroStatus = true;
      }

      if (agendamento.value.totalParticipantes.value <= numVagas.value) {
        agendamento.value.totalParticipantes.erro = false;
      } else {
        textErroTotalAlunos.value = `Número máximo de ${numVagas.value} vagas para a data selecionada!`;
        agendamento.value.totalParticipantes.erro = true;
        erroStatus = true;
      }

      for (const property in professores.value) {
        for (let [key, input] of Object.entries(professores.value[property])) {
          if (input.req && input.value === "") {
            input.erro = true;
            erroStatus = true;
          } else {
            input.erro = false;
          }
        }
      }

      return erroStatus;
    };

    const voltarStep = (step: number) => {
      steps.value[step + 1].active = false;
      steps.value[step].active = true;
    };
    const step1 = () => {
      if (validaForms()) {
        toast.add({
          severity: "error",
          summary: "Erro ao enviar",
          detail:
            "Verifique o formulário e preencha todos os campos obrigatórios!",
          life: 6000,
        });
      } else {
        console.log(agendamento.value.totalParticipantes.value);
        console.log(professores.value.length);

        totalParticipantes.value =
          agendamento.value.totalParticipantes.value +
          professores.value.length +
          1;
        if (selectedSchool.value?.typeSchool === 0) {
          valorUnidade.value = 10;
        } else {
          valorUnidade.value = 15;
        }

        //valorUnidade.value = 10;

        steps.value[0].active = false;
        steps.value[1].active = true;
      }
    };

    const step2 = () => {
      if (!regulamentoAlmoco.value.value) {
        regulamentoAlmoco.value.erro = true;
      } else {
        regulamentoAlmoco.value.erro = false;

        steps.value[1].active = false;
        steps.value[2].active = true;
      }
    };

    const step3 = () => {
      steps.value[2].active = false;
      steps.value[3].active = true;
    };

    const submitForms = async () => {
      store.dispatch("updateLoading", true);
      if (validaForms()) {
        store.dispatch("updateLoading", false);
        toast.add({
          severity: "error",
          summary: "Erro ao enviar",
          detail:
            "Verifique o formulário e preencha todos os campos obrigatórios!",
          life: 6000,
        });
      } else {
        try {
          var profs = [];

          for (const index in professores.value) {
            profs.push({
              nameTeachersVisit: professores.value[index].nameTeachers.value,
              emailTeachersVisit: professores.value[index].emailTeachers.value,
              phoneTeachersVisit: professores.value[index].phoneTeachers.value,
            });
          }

          // const dataScheduling = {
          //   nomeResponsavel: agendamento.value.nomeResponsavel.value,
          //   emailResponsavel: agendamento.value.emailResponsavel.value,
          //   cpfResponsavel: agendamento.value.cpfResponsavel.value,
          //   telefoneResponsavel: agendamento.value.telefoneResponsavel.value,
          //   escola: selectedSchool.value?.codeInepSchool,
          //   serie1: agendamento.value.serie1.value,
          //   serie2: agendamento.value.serie2.value,
          //   serie3: agendamento.value.serie3.value,
          //   data: agendamento.value.data.value,
          //   professores: profs,
          // };

          const json_profs = JSON.stringify(profs);

          const formData = new FormData();
          formData.append("file", file.value); // appending file

          formData.append(
            "nomeResponsavel",
            agendamento.value.nomeResponsavel.value.toString()
          );
          formData.append(
            "emailResponsavel",
            agendamento.value.emailResponsavel.value.toString()
          );
          formData.append(
            "cpfResponsavel",
            agendamento.value.cpfResponsavel.value.toString()
          );
          formData.append(
            "telefoneResponsavel",
            agendamento.value.telefoneResponsavel.value.toString()
          );
          formData.append(
            "escola",
            selectedSchool.value?.codeInepSchool.toString() || ""
          );

          formData.append("serie1", agendamento.value.serie1.value.toString());
          formData.append("serie2", agendamento.value.serie2.value.toString());
          formData.append("serie3", agendamento.value.serie3.value.toString());
          formData.append("data", agendamento.value.data.value.toString());
          formData.append("professores", json_profs);

          formData.append("idPixTax", identificador);
          formData.append("idPixRefeicao", identificadorRefeicao);

          const response = await SchedulingService.post(formData);
          if (response) {
            sucessoForms.value = true;
            codigoAgendamento.value = response.data.codigo;
            store.dispatch("updateLoading", false);
          }
        } catch (erro: any) {
          store.dispatch("updateLoading", false);
          erroForms.value.message =
            erro?.response?.data?.message || "Erro interno. Tente novamente!";
          erroForms.value.buttom = true;
          erroForms.value.active = true;
        }
      }
    };

    const checkInputCPF = () => {
      if (isValidCPF(agendamento.value.cpfResponsavel.value)) {
        agendamento.value.cpfResponsavel.erro = false;
      } else {
        agendamento.value.cpfResponsavel.erro = true;
      }
    };

    const checkInputEmail = (inputEmail: any) => {
      if (isEmail(inputEmail.value)) {
        inputEmail.erro = false;
      } else {
        inputEmail.erro = true;
      }
    };

    const changeInputEmail = (inputEmail: any) => {
      if (inputEmail.erro) {
        checkInputEmail(inputEmail);
      }
    };

    const checkInput = (input: any) => {
      if (input.value !== "") {
        input.erro = false;
      } else {
        input.erro = true;
      }
    };

    const changeInput = (input: any) => {
      if (input.erro) {
        checkInput(input);
      }
    };

    const statusRegulamento = (status: boolean, resp: boolean) => {
      agendamento.value.regulamento.value = resp;
      displayMaximizable.value = status;
    };

    const openCalendar = () => {
      if (selectedClickSchool.value) {
        displayCalendar.value = true;
      }
    };

    const onDayClick = (day: any) => {
      if (day.attributes.length) {
        agendamento.value.data.value = moment(day.id).format("DD/MM/YYYY");
        numVagas.value = day.attributes[0].customData.vagas;
        displayCalendar.value = false;
      }
    };

    const checkRegulamento = () => {
      if (!lerRugulamento.value) {
        lerRugulamento.value = true;
        statusRegulamento(true, true);
      }
    };

    watchEffect(() => {
      if (agendamento.value.data.erro) {
        if (
          agendamento.value.data.value === null ||
          agendamento.value.data.value === ""
        ) {
          agendamento.value.data.erro = true;
        } else {
          agendamento.value.data.erro = false;
        }
      }
    });

    watchEffect(() => {
      if (agendamento.value.regulamento.erro) {
        if (agendamento.value.regulamento.value === false) {
          agendamento.value.regulamento.erro = true;
        } else {
          agendamento.value.regulamento.erro = false;
        }
      }
    });

    watchEffect(() => {
      if (agendamento.value.totalParticipantes.erro) {
        if (agendamento.value.totalParticipantes.value >= 10) {
          agendamento.value.totalParticipantes.erro = false;
        } else {
          textErroTotalAlunos.value = "Mínimo de 10 alunos para o agendamento";
          agendamento.value.totalParticipantes.erro = true;
        }

        if (agendamento.value.totalParticipantes.value <= numVagas.value) {
          agendamento.value.totalParticipantes.erro = false;
        } else {
          textErroTotalAlunos.value = `Número máximo de ${numVagas.value} vagas para a data selecionada!`;
          agendamento.value.totalParticipantes.erro = true;
        }
      }
    });

    watchEffect(() => {
      if (
        agendamento.value.cpfResponsavel.erro &&
        agendamento.value.cpfResponsavel.value !== ""
      ) {
        checkInputCPF();
      }
    });

    watchEffect(() => {
      if (
        agendamento.value.telefoneResponsavel.erro &&
        agendamento.value.telefoneResponsavel.value !== ""
      ) {
        checkInput(agendamento.value.telefoneResponsavel);
      }
    });
    return {
      regulamento: constants.regulamento,
      datasDisponiveis: computed(() => store.state.date.dates),
      selectedSchool,
      removeSchool,
      selectedClickSchool,
      searchSchool,
      filteredSchool,
      clickSchool,
      emptySchool,
      erroSchool,
      professores,
      removeProfessor,
      addProfessor,
      agendamento,
      submitForms,
      checkInputCPF,
      checkInputEmail,
      changeInputEmail,
      checkInput,
      changeInput,
      statusRegulamento,
      displayMaximizable,
      erroForms,
      sucessoForms,
      codigoAgendamento,
      displayCalendar,
      openCalendar,
      onDayClick,
      attributes,
      numVagas,
      textErroTotalAlunos,
      lerRugulamento,
      checkRegulamento,
      steps,
      step1,
      voltarStep,
      totalParticipantes,
      valorUnidade,
      regulamentoAlmoco,
      step2,
      step3,
      codigoPix,
      copiarTexto,
      file,
      handleFileUpload,
      removeUpload,
      erroUpload,
    };
  },
});
