import http from '@/http'

class SchoolService {
    async getFilter(filter: string) {
        const response = await http.get(`escolas/${filter}`)
        return response.data
    }
}

export default new SchoolService()
